import React, { useState } from "react";
import DataTable from "../common/Tables/DataTable";
import config from "../../constants/apiList";
import moment from "moment";
import "./ProductForms.css";
const dateToday = moment().format("YYYY-MM-DD");

export default function ProductForms() {
  const [dates, setDates] = useState({
    start: dateToday,
    end: dateToday
  });
  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };
  const reqBody = {
    startDate: Number(moment(dates.start).utcOffset(330).format("x")),
    admin_key: localStorage.getItem("bwLogin"),
    endDate: Number(moment(dates.end).utcOffset(330).format("x")) + (24 * 60 * 60 * 1000)
  };
  const rowStyle = {
    //maxHeight: '10px',
    // padding:'1px !important',
    // scroll:'overflow',
    // lineHeight:'1',
    // background:'yellow'
  }
  const objectToCSVRow = function (dataObject) {
    var dataArray = new Array;
    var cols = orderCols2;

    // console.log("dataObject", dataObject)
    // console.log("orderCols", orderCols)

    if (Array.isArray(dataObject)) {

      for (var i = 0; i < cols.length; i++) {
        var key = cols[i].title;
        // console.log("key", key);
        var innerValue = dataObject.includes(key) === false || dataObject.includes(key) === undefined ? '' : key.toString();
        var result = innerValue.replace(/"/g, '""');
        result = '"' + result + '"';
        dataArray.push(result);
      }
    }

    else {

      for (var i = 0; i < cols.length; i++) {
        var key = cols[i].title;
        // console.log("key", key);
        var innerValue = dataObject[key] === null || dataObject[key] === undefined ? '' : Array.isArray(dataObject[key]) ? dataObject[key].join(",") : dataObject[key].toString();
        var result = innerValue.replace(/"/g, '""');
        result = '"' + result + '"';
        dataArray.push(result);
      }

    }
    // This below code snippet is not able to handle the order from UI into CSV file.
    // for (var o in dataObject) {
    //     var innerValue = dataObject[o]===null || dataObject[o] === undefined?'':dataObject[o].toString();
    //     var result = innerValue.replace(/"/g, '""');
    //     result = '"' + result + '"';
    //     dataArray.push(result);
    // }
    return dataArray.join(',') + '\r\n';
  }

  const exportToCSV = function (arrayOfObjects) {
    if (!arrayOfObjects.length) {
      return;
    }
    var csvContent = "data:text/csv;charset=utf-8,";
    csvContent += objectToCSVRow(Object.keys(arrayOfObjects[0]));
    arrayOfObjects.forEach(function (item) {
      csvContent += objectToCSVRow(item);
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Product Forms.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div className="admin-page-container">
      <div className="dategroup">
        <form className="form">
          <input
            type="date"
            className="input"
            value={dates.start}
            onChange={e => handleChange(e, "start")}
          />
          <input
            type="date"
            className="input"
            value={dates.end}
            onChange={e => handleChange(e, "end")}
          />
        </form>
      </div>
      <div className="forms">
        <DataTable
          title="Product Forms"
          cols={orderCols2}
          url={config.product_forms}
          reqBody={reqBody}
          rowStyle={rowStyle}
          fromS3 = {true}
          exportCsv={(columns, data) => {
            exportToCSV(data);
          }}
        />
      </div>
    </div>
  );
}

const orderCols2 = [
  "customer.customerId",
  "customer.name",
  "customer.phoneNumber",
  "customer.email",
  "answers.Basic Details.first_name",
  "answers.Basic Details.last_name",
  "answers.Basic Details.age",
  "answers.Basic Details.weight",
  "answers.Basic Details.height",
  "answers.Basic Details.source",
  "answers.Basic Details.medium",
  "answers.Basic Details.primary_source",
  "Timestamp",
  "categoriesData" ,
  "formID",
  "answers.Skin.skin_concern",
  "answers.Skin.skin_dehydrated",
  "answers.Skin.skin_elasticity",
  "answers.Skin.skin_type",
  "answers.Skin.skin_sensitive",
  "answers.Skin.skin_acne",
  "answers.Skin.skin_notice_acne",
  "answers.Skin.skin_acne_freq",
  "answers.Skin.skin_dark_spots",
  "answers.Skin.skin_aging_identify",
  "answers.Skin.skin_wrinkles",
  "answers.Skin.skin_notice_wrinkles",
  "answers.Skin.skin_firm",
  "answers.Skin.skin_pigmentation",
  "answers.Skin.skin_pigmentation_extent",
  "answers.Skin.skin_pigmentation_spots",
  "answers.Skin.skin_pigmentation_identify",
  "answers.Skin.skin_sensitive_reason",

  "answers.Hair.hair_condition",
  "answers.Hair.hair_notice",
  "answers.Hair.hair_loss",
  "answers.Hair.hair_concern",
  "answers.Hair.hair_tried",
  "answers.Hair.hair_reason",
  "answers.Hair.hair_identify",
  "answers.Hair.hair_earlier",
  "answers.Hair.hair_pony",
  "answers.Hair.hair_avoid",

  "answers.Weight.weight_identify",
  "answers.Weight.weight_water",
  "answers.Weight.weight_junk",


  "answers.Nutrition.nutrition_meal",
  "answers.Nutrition.nutrition_food",
  "answers.Nutrition.nutrition_healthy",
  "answers.Nutrition.nutrition_issues",
  "answers.Nutrition.nutrition_habits",
  "answers.Fitness.fitness_excercise",
  "answers.Fitness.fitness_fatigue",

  "answers.Stress.stress_level",
  "answers.Sleep.sleep_hours",
  "answers.Sleep.sleep_quality",


  "answers.Health.health_identify",
  "answers.Health.health_issues",
  "answers.Health.any_medication",

  "results.Skin.score",
  "results.Skin.icon",
  "results.Skin.Diagnosis",
  "results.Skin.Advice.Care",
  "results.Skin.Advice.Diet",
  "results.Skin.Advice.Supplements",
  "results.Hair.score",
  "results.Hair.icon",
  "results.Hair.Diagnosis",
  "results.Hair.Advice.Care",
  "results.Hair.Advice.Diet",
  "results.Hair.Advice.Supplements",
  "results.Weight.score",
  "results.Weight.icon",
  "results.Weight.Diagnosis",
  "results.Weight.Advice.Diet",
  "results.Weight.Advice.Digestion",
  "results.Nutrition.score",
  "results.Nutrition.icon",
  "results.Nutrition.Diagnosis",
  "results.Nutrition.Advice.Nutrition",
  "results.Nutrition.Advice.Avoid Junk",
  "results.Nutrition.Advice.Routine",
  "results.Fitness.score",
  "results.Fitness.icon",
  "results.Fitness.Diagnosis",
  "results.Fitness.Advice.Diet",
  "results.Fitness.Advice.Supplements",
  "results.Stress.score",
  "results.Stress.icon",
  "results.Stress.Diagnosis",
  "results.Stress.Advice.Diet",
  "results.Stress.Advice.Exercise ",
  "results.Stress.Advice.Relaxation",
  "results.Sleep.score",
  "results.Sleep.icon",
  "results.Sleep.Diagnosis",
  "results.Sleep.Advice.Diet",
  "results.Sleep.Advice.Environment",
  "results.Sleep.Advice.Routine",
  "total_score",
  "products",
  "totalPrice",
  "scheduleDate",
  "scheduleSlot"
  // "recommendedProducts",
].map(col => {
  if (col !== "answers.Hair.concern_1") {
    return {
      title: col,
      field: col,
    };
  }
});

/*
//ORDER FROM JSON SHEET IN DEMO.JS
'Basic Details.select_category',
  'Basic Details.input',
  'Basic Details.defaults',
  'Skin.skin_concern',
  'Skin.skin_dehydrated',
  'Skin.skin_elasticity',
  'Skin.skin_type',
  'Skin.skin_sensitive',
  'Skin.skin_acne',
  'Skin.skin_notice_acne',
  'Skin.skin_acne_freq',
  'Skin.skin_dark_spots',
  'Skin.skin_aging_identify',
  'Skin.skin_wrinkles',
  'Skin.skin_notice_wrinkles',
  'Skin.skin_firm',
  'Skin.skin_pigmentation',
  'Skin.skin_pigmentation_extent',
  'Skin.skin_pigmentation_spots',
  'Skin.skin_pigmentation_identify',
  'Skin.skin_sensitive_reason',

  'Hair.hair_condition',
  'Hair.hair_notice',
  'Hair.hair_loss',
  'Hair.hair_concern',
  'Hair.hair_tried',
  'Hair.hair_reason',
  'Hair.hair_identify',
  'Hair.hair_earlier',
  'Hair.hair_pony',
  'Hair.hair_avoid',

  'Weight.weight_identify',
  'Weight.weight_water',
  'Weight.weight_junk',


  'Nutrition.nutrition_meal',
  'Nutrition.nutrition_food',
  'Nutrition.nutrition_healthy',
  'Nutrition.nutrition_issues',
  'Nutrition.nutrition_habits',

  'Fitness.fitness_excercise',
  'Fitness.fitness_fatigue',

  'Stress.stress_level',

  'Sleep.sleep_hours',
  'Sleep.sleep_quality',

  'Health.health_identify',
  'Health.health_issues',
  'Health.any_medication'
  */