import React, { useEffect, useRef, useState } from "react";
import CONFIG_CONSTANTS from "../../constants/apiList";
import moment from "moment";
import Axios from "axios";
import Loader from "react-loader-spinner";
import './Subscriptions.css';
import { useHistory } from "react-router-dom";

const dateToday = moment().format("YYYY-MM-DD");

export default function Subscriptions() {

	const history = useHistory();

	const [subscriptions, setSubscriptions] = useState([]);
	const [subscriptionNotFound, setSubscriptionNotFound] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [searchLoading, setSearchLoading] = useState(false);

	const getSubscriptions = () => {

		let url = CONFIG_CONSTANTS.GET_SUBSCRIPTIONS;

        Axios.get(url)
                .then(response => {
					let data = response.data;

					setSubscriptions(data);

                    setIsLoading(false);

                })
                .catch(error => console.log(error));

	}

	useEffect(() => {
		getSubscriptions();
	}, []);

	const showDetails = (id) => {
		history.push('/product/subscription/' + id);
	}

	const searchInput = useRef(null);

	const search = (e) => {

		setSubscriptionNotFound(false);

		e.preventDefault();

		let url = CONFIG_CONSTANTS.SEARCH_SUBSCRIPTIONS + '?keyword=' + searchInput.current.value;

		setSearchLoading(true);

        Axios.get(url)
                .then(response => {
                    let data = response.data;

					if (data.length == 0) {
						setSubscriptionNotFound(true);
					} else {
						setSubscriptions(data);
					}

                    setSearchLoading(false);

                })
                .catch(error => console.log(error));

	}

	return isLoading ? (
        <div style={{position: 'fixed', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-100px'}}>
            <Loader
                type="TailSpin"
                color="#000000"
                height={100}
                width={100}
            />
        </div>
    ) : (

		<div className="admin-page-container">

			<div>
				<form onSubmit={e => search(e)}>
					<input name="search" ref={searchInput} />
					<input type="submit" value="Search" onClick={e => search(e)} />
				</form>
			</div>

			{searchLoading ? (
				 <div style={{position: 'fixed', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-100px'}}>
					<Loader
						type="TailSpin"
						color="#000000"
						height={100}
						width={100}
					/>
				</div>
			) : subscriptionNotFound ? (
				<div style={{position: 'fixed', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-100px'}}>
					<h4>No Subscription found for "{searchInput.current.value}"</h4>
				</div>
			) : (
				<table className="table">
					<tr>
						<th>Order Id</th>
						<th>Name</th>
						<th>Ordered On</th>
						<th>Next Renews</th>
						<th>Last Renewed</th>
						<th>Frequency</th>
						<th>Subscription Value</th>
						<th>Order Value</th>
						<th>Product SKU</th>
						<th>Product Name</th>
					</tr>
					{subscriptions && subscriptions.map(subscription => (
						<tr className="subscription-table-row" onClick={e => showDetails(subscription.id)}>
							<td>{subscription.increment_id}</td>
							<td>{subscription.name}</td>
							<td>{moment(subscription.created_at).format('ll')}</td>
							<td>{moment(subscription.next_renewed).format('ll')}</td>
							<td>{moment(subscription.last_renewed).format('ll')}</td>
							<td>{subscription.frequency}</td>
							<td>Rs. {subscription.sub_value}</td>
							<td>Rs. {subscription.order_value}</td>
							<td>{subscription.product_sku}</td>
							<td>{subscription.product_name}</td>
						</tr>
					))}
				</table>
			)}


		</div>

	);

};
