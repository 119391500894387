import React, { useState } from "react";
import DataTable from "../common/Tables/DataTable";
import config from "../../constants/apiList";
import moment from "moment";

const dateToday = moment().format("YYYY-MM-DD");

export default function SubscriptionOrders() {
  const [dates, setDates] = useState({
    start: dateToday,
    end: dateToday
  });
  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };
  const reqBody = {
    type: "orders",
    startDate:dates.start,
    endDate: dates.end
  };
  return (
    <div className="admin-page-container">
      <div className="dategroup">
        <form className="form">
          <input
            type="date"
            className="input"
            value={dates.start}
            onChange={e => handleChange(e, "start")}
          />
          <input
            type="date"
            className="input"
            value={dates.end}
            onChange={e => handleChange(e, "end")}
          />
        </form>
      </div>
      <DataTable
        title="Subscription Orders"
        cols={orderCols}
        url={config.subscription_product_order}
        reqBody={reqBody}
        fromS3={false}
      />
    </div>
  );
}

const orderCols = [
  "subscription_order_id",
  "date_of_subscription_order",
  "child_order_id",
  "date_of_child_order",
  "product_amount",
  "total_order_amount",
  "status",
  "product_name",
  "product_sku",
  "payment_method",
  "payment_reference",
].map(col => {
  return {
    title: col,
    field: col
  };
});
