import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Warehouse.scss';
function WarehouseDashboard() {
  return (
    <div>
      <h5 className="title">WAREHOUSE DASHBOARD</h5>
      <Link to="/warehouse/unshippedOrders">
        <button className="secondary">UNSHIPPED ORDERS</button>
      </Link>
    </div>
  );
}
export default WarehouseDashboard;
