import React, { useState, useEffect } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import "./Products.css";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import CONFIG_CONSTANTS from "../../constants/apiList";
import { UserContext } from '../../App';
import Popup from './Popup';
import { Modal, Button } from 'react-bootstrap';
function Products() {
  const [result, setResult] = useState([]);
  const [fileName, setFileName] = useState("");
  const [jsonData, setJsonData] = useState("");
  const [jsonDataForDiff, setJSONDataForDiff] = useState({});
  const [actualJson, setActualJson] = useState({});
  const [stagingData, setStagingData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [productionData, setProductionData] = useState({});
  const [enablePushToStaging, setEnablePushToStaging] = useState(false);
  const [enablePushToProduction, setEnablePushToProduction] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("alert");
  const [alertMessage, setAlertMessage] = useState("");
  const [page, setPage] = useState("");
  const [stagingbucket, setStagingBucket] = useState("");
  const [productbucket, setProductBucket] = useState("");
  const [reRender, setReRender] = useState("");
  const history = useHistory();
  const [availableKeys, setAvailableKeys] = useState([]);
  const [showNewKey, setShowNewKey] = useState(false);
  const [newKey, setNewKey] = useState([]);
  const [newValue, setNewValue] = useState([]);
  const [temporaryParent, setTemporaryParent] = useState({});
  const [newKeyType, setNewKeyType] = useState([]);
  const [showProductionPopup, setShowProductionPopup] = useState(false);
  const [disableFAuto, setDisableFAuto] = useState(false); // Pass True to Enable F_Auto Checking
  const availableProductKeys = ["photos", "category", "sections", "suggested_prod_sku", "suggested_products", "frequently_bought_id","meta",
    "banner_image", "usage_instruction", "regime_step", "rating", "user_recommended", "users_reviewed", "size", "usage", "video", "usage_instructions", "usage_frequency", "usage_unit", "short_description",
    "whats_in_the_kit", "usage2", "usage3", "testimonials", "usage4", "regime_title", "product_name", "compare","important_information","use_case","description","mechanism",
    "why_this_works", "FOR", "WITH", "BECAUSE", "claims", "key_ingredients", "list", "buying_options_sku", "key_ingredients", "full_ingredients", "who_its_for", "usage", "title", "steps", "effect_you_feel", "text", "customer_name", "customer_note", "effects", "faq"];

  const availableCategoryKeys = ["header", "title", "sections", "consult", "complete_regime", "steps", "why_this_works",
    "for", "contains", "because", "claims", "what_to_expect", "experts", "ingredients", "items", "how_it_works", "options", "testimonials",
    "customers", "photos", "safety_information", "who_its_for", "display_kits"];

  const availableAllProductKeys = ["category", "bestSelling", "shopSingle", "display", "desktop_image", "mobile_image", "usage_note",
    "prod_subtitle", "prod_url", "meetExperts", "title", "subtitle", "options"];

  const availableHomeKeys = ["landingCarousel", "landingPrograms", "subtitle", "options", "landingPersonalized",
    "landingRecommended", "products", "landingHowItWorks", "landingMeetExperts", "landingCustomerComments", "landingBanner"
    , "options", "expert"];

  const availableIngredientKeys = ['banner', 'sections'];
  useEffect(() => {
    let pagename = history.location.pathname;
    pagename = pagename.substring(1, pagename.length).split("/")[1];
    setPage(pagename);
    switch (pagename) {
      case 'categories':
        setStagingBucket("bebodywise-products-staging");
        setProductBucket("bebodywise-products-prod");
        setAvailableKeys(availableCategoryKeys);
        break;
      case 'products-raw':
      case 'products':
        setStagingBucket("bebodywise-products-staging");
        setProductBucket("bebodywise-products-prod");
        setAvailableKeys(availableProductKeys);
        break;
      case 'custom-page':
        setStagingBucket("bebodywise-custompage-staging");
        setProductBucket("bebodywise-custompage-prod");
        break;
      case 'regimes':
        setStagingBucket("bebodywise-products-staging");
        setProductBucket("bebodywise-products-prod");
        break;
      case 'allproducts':
        setStagingBucket("bebodywise-products-staging");
        setProductBucket("bebodywise-products-prod");
        setAvailableKeys(availableAllProductKeys);
        break;
      case 'forms':
      case 'mm-forms':
        setStagingBucket("forms-service-json");
        setProductBucket("forms-service-json");
        break;
      case 'home':
        setStagingBucket('bebodywise-pagedata-staging');
        setProductBucket('bebodywise-pagedata-prod');
        setAvailableKeys(availableHomeKeys);
        break;
      case 'newhome':
      case 'faq':
        setStagingBucket('bebodywise-pagedata-staging');
        setProductBucket('bebodywise-pagedata-prod');
        break;
      case 'notifications':
        setStagingBucket('bebodywise-notifications-staging');
        setProductBucket('bebodywise-notifications-prod');
        break;
      case 'notification-bars':
        setStagingBucket('bebodywise-notification-bars-staging');
        setProductBucket('bebodywise-notification-bars-prod');
        break;
      case 'search':
        setStagingBucket('bebodywise-search-staging');
        setProductBucket('bebodywise-search-prod');
        break;
      case 'subscription':
        setStagingBucket('bebodywise-subscription-staging');
        setProductBucket('bebodywise-subscription-prod');
        break;
      case 'refer-and-earn':
        setStagingBucket('bebodywise-refer-and-earn-staging');
        setProductBucket('bebodywise-refer-and-earn-prod');
        break;
      case 'pincode':
        setStagingBucket('bebodywise-pincode-staging');
        setProductBucket('bebodywise-pincode-prod');
        break;
      case 'reviews-and-questions':
        setStagingBucket('bebodywise-reviews-and-questions-staging');
        setProductBucket('bebodywise-reviews-and-questions-prod');
        break;
      case 'ingredients':
        setStagingBucket('bebodywise-ingredients-staging');
        setProductBucket('bebodywise-ingredients-prod');
        setAvailableKeys(availableIngredientKeys);
        break;
      case 'mm-android-products-raw':
      case 'mm-products-raw':
        setStagingBucket('manmatters-products-staging');
        setProductBucket('manmatters-products-prod');
        break;
      case 'lj-products-raw':
        setStagingBucket('littlejoys-products-staging');
        setProductBucket('littlejoys-products-prod');
        break;
      case 'lj-pages':
        setStagingBucket('littlejoys-pagedata-staging');
        setProductBucket('littlejoys-pagedata-prod');
        break;
      case 'mm-categories':
        setStagingBucket('manmatters-stage-json');
        setProductBucket('manmatters-prod-json');
        break;
      case 'mm-android-jsons':
      case 'mm-app-categories':
        setStagingBucket('manmatters-stage-json');
        setProductBucket('manmatters-prod-json');
        break;
      case 'community':
        setStagingBucket('bebodywise-community-staging');
        setProductBucket('bebodywise-community-prod');
        break;
      case 'direct-assesment':
      case 'mm-direct-assesment':
        setStagingBucket('forms-service-json');
        setProductBucket('forms-service-json');
        break;
      case 'direct-assesment-flow':
        setStagingBucket('bebodywise-direct-assesment-flow-staging');
        setProductBucket('bebodywise-direct-assesment-flow-prod');
        break;
      case 'consult':
        setStagingBucket('bebodywise-consult-staging');
        setProductBucket('bebodywise-consult-prod');
        break;
      case 'wellness-manual':
        setStagingBucket('bebodywise-wellness-manual-staging');
        setProductBucket('bebodywise-wellness-manual-prod');
        break;
      case 'sitemap':
        setStagingBucket('bebodywise-sitemap-staging');
        setProductBucket('bebodywise-sitemap-prod');
        break;
      case 'free-gift-mm':
        setStagingBucket('free-gift-rules');
        setProductBucket('free-gift-rules');
        break;
      case 'free-gift-bw':
        setStagingBucket('free-gift-rules');
        setProductBucket('free-gift-rules');
        break;
      case 'free-gift-lj':
        setStagingBucket('free-gift-rules');
        setProductBucket('free-gift-rules');
        break;
      case 'mm-concerns':
        setStagingBucket('manmatters-stage-json');
        setProductBucket('manmatters-prod-json');
        break;
      case 'mw-configurations-MM':
        setStagingBucket('mw-configurations-staging');
        setProductBucket('mw-configurations-prod');
        break;
      case 'mw-configurations-BW':
        setStagingBucket('mw-configurations-staging');
        setProductBucket('mw-configurations-prod');
        break;
      case 'mw-configurations-LJ':
        setStagingBucket('mw-configurations-staging');
        setProductBucket('mw-configurations-prod');
        break;
      case 'mm-homepage':
        setStagingBucket('manmatters-stage-json');
        setProductBucket('manmatters-prod-json');
        break;
      case 'mm-all-products':
        setStagingBucket('manmatters-stage-json');
        setProductBucket('manmatters-prod-json');
        break;
      case 'mm-pdp-url-map':
        setStagingBucket('manmatters-stage-json');
        setProductBucket('manmatters-prod-json');
        break;
      case 'slot-capacity':
        setStagingBucket('dochub-staging');
        setProductBucket('dochub-prod');
        break;
      case 'rules-json':
        setStagingBucket('zeus-rules-stage');
        setProductBucket('zeus-rules-prod');
        break;
    }
  }, []);

  const getData = fromProduction => {
    setSpinner(true);
    const bucketName = fromProduction ? productbucket : stagingbucket;
    let productId = '';
    switch (page) {
      case 'mm-products-raw':  productId =  'products/' + fileName + '.json'; break;
      case 'mm-android-products-raw':  productId =  'products/android/' + fileName + '.json'; break;
      case 'lj-products-raw':  productId =  'products/' + fileName + '.json'; break;
      case 'lj-pages':  productId = fileName + '.json'; break;
      case 'mm-categories':  productId =  fileName + '.json'; break;
      case 'mm-app-categories':  productId =  fileName + '.json'; break;
      case 'mm-android-jsons':  productId = 'android/' + fileName + '.json'; break;
      case 'products-raw':
      case 'products': productId = 'products/' + fileName + '.json'; break;
      case 'allproducts':
      case 'categories': productId = 'categories/' + fileName + '.json'; break;
      case 'regimes': productId = 'regimes/' + fileName + '.json'; break;
      case 'forms': productId = 'BW/' + fileName + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'mm-forms': productId = fileName + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'home':
      case 'newhome':
      case 'faq': productId = fileName + '.json'; break;
      case 'notifications': productId = 'notifications/' + fileName + '.json'; break;
      case 'notification-bars': productId = 'notification-bars.json'; break;
      case 'search': productId = 'search.json'; break;
      case 'subscription': productId = 'subscription.json'; break;
      case 'refer-and-earn': productId = 'refer-and-earn.json'; break;
      case 'reviews-and-questions': productId = 'reviews-and-questions.json'; break;
      case 'ingredients': productId = `ingredients/${fileName}.json`; break;
      case 'pincode': productId = `pincode.json`; break;
      case 'community': productId = 'community.json'; break;
      case 'direct-assesment-flow': productId = 'direct-assesment-flow.json'; break;
      case 'custom-page': productId = fileName + '.json'; break;
      case 'direct-assesment': productId = `BW/app_da_questions_${fromProduction ? 'prod' : 'staging' }.json`; break;
      case 'mm-direct-assesment': productId = `app_da_questions_${fromProduction ? 'prod' : 'staging' }_v2.json`; break;
      case 'consult': productId = fileName + '.json'; break;
      case 'wellness-manual': productId = 'wellness-manual.json'; break;
      case 'sitemap': productId =  'sitemap.json'; break;
      case 'free-gift-mm': productId = 'mm_gift' + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'free-gift-bw': productId = 'bw_gift' + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'free-gift-lj': productId = 'lj_gift' + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'mm-concerns': productId = 'mm-concerns.json'; break;
      case 'mw-configurations-MM': productId = 'cod_charges_MM.json'; break;  
      case 'mw-configurations-BW': productId = 'cod_charges_BW.json'; break;  
      case 'mw-configurations-LJ': productId = 'cod_charges_LJ.json'; break;  
      case 'mm-homepage': productId = 'web_homepage.json'; break;
      case 'mm-all-products': productId = 'web_all_products.json'; break;
      case 'mm-pdp-url-map': productId = 'mm_pdp_url_map.json'; break;
      case 'slot-capacity': productId = 'slot-capacity.json'; break;
      case 'rules-json': productId = fileName + '.json'; break;
    }
    if (productId) {
      Axios.post(CONFIG_CONSTANTS.dashboard_api + '/getProductData', { bucketName, productId }, { headers: { "authorization": 'Bearer ' + localStorage.getItem('jwt') } })

        .then(result => {
          setSpinner(false);
          if (result.data.error) {
            setShowAlert(true);
            setAlertType("alert");
            setAlertMessage("Please enter correct File Name");
            setTimeout(() => setShowAlert(false), 4000);
          }
          else {
            setActualJson(result.data.data);
            setJsonData(JSON.stringify(result.data.data, undefined, 4)) //Pretty Print JSON
          }
        })
        .catch(err => {
          setSpinner(false);
          console.log(err);
        });
    }
  };
  const Alert = () => {
    return <div className={alertType}>{alertMessage}</div>;
  };


  const validateURLUtility = (json, result) => {
    for (let key in json) {
      if (Array.isArray(json[key])) {
        validateURLUtility(json[key], result);
      }
      else if (typeof (json[key]) === "object") {
        validateURLUtility(json[key], result);
      }
      else if (typeof json[key] === "string") {
        let value = json[key]
        if (value.startsWith("https://") && (value.endsWith(".png") || value.endsWith(".jpg"))) {
          if (!(value.includes("f_auto,w_800,c_limit"))) {
            var obj = {};
            obj[key] = value;
            result.push(obj)
          }

        }

      }
    }
  }




  const validateURL = (json) => {

    let result = [];
    if (typeof (json) === "string") {
      validateURLUtility(JSON.parse(json), result)
    }
    else {
      for (let key of Object.keys(json)) {
        if (Array.isArray(json[key])) {
          validateURLUtility(json[key], result);
        }
        else if (typeof (json[key]) === "object") {
          validateURLUtility(json[key], result);
        }
        else if (typeof json[key] === "string") {
          let value = json[key]
          if (value.startsWith("https://") && (value.endsWith(".png") || value.endsWith(".jpg"))) {
            if (!(value.includes("f_auto,w_800,c_limit"))) {
              var obj = {};
              obj[key] = value;
              result.push(obj)
            }
          }
        }
      }
    }

    // if (result.length > 0) {
    //   console.log("result", result)
    //   alert(JSON.stringify(result));
    // }

    setResult(result);
    if (result.length > 0)
      throw new Error('Exception message');
  }


  const validateJson = (showMessage = false) => {
    console.log('88',page);
    try {
      if (page === 'products' || page === 'categories'|| page === 'home') {
        JSON.parse(JSON.stringify(actualJson, undefined, 4));
        setJsonData(JSON.stringify(actualJson, undefined, 4));
        // console.log(actualJson);
        console.log("Reching 1");
        if (disableFAuto)
          validateURL(actualJson);
      }
      else {
        console.log("Reching 2");
        JSON.parse(jsonData);
        if (disableFAuto)
          validateURL(jsonData);


      }
      setEnablePushToStaging(true);
      if (showMessage) {
        setShowAlert(true);
        setAlertType("alert success");
        setAlertMessage("Valid");
        setTimeout(() => setShowAlert(false), 4000);
      }
    } catch (e) {
      setShowAlert(true);
      setAlertType("alert");
      setAlertMessage("Invalid");
      setTimeout(() => setShowAlert(false), 4000);
    }
  };
  const pushToS3 = async (fromProduction = false) => {
    setSpinner(true);
    const bucketName = fromProduction ? productbucket : stagingbucket;

    let productId = '';
    switch (page) {
      case 'mm-products-raw':productId =  'products/' + fileName + '.json'; break;
      case 'mm-android-products-raw':productId =  'products/android/' + fileName + '.json'; break;
      case 'lj-products-raw':productId =  'products/' + fileName + '.json'; break;
      case 'lj-pages':productId = fileName + '.json'; break;
      case 'mm-categories':productId =  fileName + '.json'; break;
      case 'mm-app-categories':productId =  fileName + '.json'; break;
      case 'mm-android-jsons':productId = 'android/' + fileName + '.json'; break;
      case 'products-raw':
      case 'products': productId = 'products/' + fileName + '.json'; break;
      case 'allproducts':
      case 'categories': productId = 'categories/' + fileName + '.json'; break;
      case 'regimes': productId = 'regimes/' + fileName + '.json'; break;
      case 'forms': productId = 'BW/' + fileName + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'mm-forms': productId = fileName + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'home':
      case 'newhome':
      case 'faq': productId = fileName + '.json'; break;
      case 'notifications': productId = 'notifications/' + fileName + '.json'; break;
      case 'notification-bars': productId = 'notification-bars.json'; break;
      case 'search': productId = 'search.json'; break;
      case 'subscription': productId = 'subscription.json'; break;
      case 'refer-and-earn': productId = 'refer-and-earn.json'; break;
      case 'reviews-and-questions': productId = 'reviews-and-questions.json'; break;
      case 'ingredients': productId = `ingredients/${fileName}.json`; break;
      case 'pincode': productId = `pincode.json`; break;
      case 'community': productId = 'community.json'; break;
      case 'direct-assesment-flow': productId = 'direct-assesment-flow.json'; break;
      case 'custom-page': productId = fileName + '.json'; break;
      case 'direct-assesment': productId = `BW/app_da_questions_${fromProduction ? 'prod' : 'staging' }.json`; break;
      case 'mm-direct-assesment': productId = `app_da_questions_${fromProduction ? 'prod' : 'staging' }_v2.json`; break;
      case 'consult': productId = fileName + '.json'; break;
      case 'wellness-manual': productId = 'wellness-manual.json'; break;
      case 'sitemap': productId = 'sitemap.json'; break;
      case 'free-gift-mm': productId = 'mm_gift' + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'free-gift-bw': productId = 'bw_gift' + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'free-gift-lj': productId = 'lj_gift' + (fromProduction ? '_prod' : '_staging') + '.json'; break;
      case 'mm-concerns': productId = 'mm-concerns.json'; break;
      case 'mw-configurations-MM': productId = 'cod_charges_MM.json'; break;
      case 'mw-configurations-BW': productId = 'cod_charges_BW.json'; break;
      case 'mw-configurations-LJ': productId = 'cod_charges_LJ.json'; break;
      case 'mm-homepage': productId = 'web_homepage.json'; break;
      case 'mm-all-products': productId = 'web_all_products.json'; break;
      case 'mm-pdp-url-map': productId = 'mm_pdp_url_map.json'; break;
      case 'slot-capacity': productId = 'slot-capacity.json'; break;
      case 'rules-json': productId = fileName + '.json'; break;
    }

    const brandMap = {
      // "mm-products-raw":"mm",
      "products-raw": "bw",
      // "mm-categories": "mm_category",
      "categories": "bw_category"
    }
    if(brandMap[page]){
      const brand = brandMap[page];

      const data = {
        "id": fileName,
        "productBrand": brand,
        "data": JSON.parse(jsonData)
      };
      const config = {
        method: 'put',
        url: fromProduction? CONFIG_CONSTANTS.OS_URL_prod: CONFIG_CONSTANTS.OS_URL_stg,
        headers: {
          'opensearchkey':fromProduction? CONFIG_CONSTANTS.OS_KEY_prod:CONFIG_CONSTANTS.OS_KEY_stg,
          'Content-Type': 'application/json'
        },
        data : data,
      };
      try {
        await Axios(config);
      } catch (e) {
        console.log('Error in uploading to os ', e);
      }
    }

    Axios.post(CONFIG_CONSTANTS.dashboard_api + '/setProductData',
      { bucketName, productId, data: jsonData }, { headers: { "authorization": 'Bearer ' + localStorage.getItem('jwt') } })
      .then(result => {
        setSpinner(false);
        if (result.data.message === "success") {
          console.log("success");
          if (!fromProduction) setEnablePushToProduction(true);
          setShowAlert(true);
          setAlertType("alert success");
          setAlertMessage(
            fromProduction ? "Pushed to Production" : "Pushed to Staging"
          );
          setTimeout(() => setShowAlert(false), 4000);
        } else {
          console.log("failed");
          setShowAlert(true);
          setAlertType("alert");
          setAlertMessage("Error occured");
          setTimeout(() => setShowAlert(false), 4000);
        }
      })
      .catch(err => {
        setSpinner(false);
        console.log(err);
      });
  };
  const clear = () => {
    setFileName("");
    setJsonData("");
    setActualJson({});
    setEnablePushToProduction(false);
    setEnablePushToStaging(false);
    setShowPopup(false);
    setStagingData({});
    setProductionData({});
  }
  function set(path, value, index = null) {
    let schema = actualJson;
    let len = path.length;
    for (let i = 0; i < len - 1; i++) {
      let elem = path[i];
      if (!schema[elem]) schema[elem] = {}
      schema = schema[elem];
    }
    index == null ? schema[path[len - 1]] = value : schema[path[len - 1]][index] = value;
  }
  function addItem(path) {
    let schema = actualJson;
    let len = path.length;
    for (let i = 0; i < len - 1; i++) {
      let elem = path[i];
      if (!schema[elem]) schema[elem] = {}
      schema = schema[elem];
    }
    schema[path[len - 1]].push("")
    setReRender(!reRender);
  }
  function deleteItem(path, index) {
    let schema = actualJson;
    let len = path.length;
    for (let i = 0; i < len - 1; i++) {
      let elem = path[i];
      if (!schema[elem]) schema[elem] = {}
      schema = schema[elem];
    }
    schema[path[len - 1]].splice(index, 1);
    setReRender(!reRender);
  }
  var move = function(array, index, delta) {
    var newIndex = index + delta;
    if (newIndex < 0  || newIndex == array.length) return;
    var indexes = [index, newIndex].sort();
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
  };
  function moveUp(path, index) {
    let schema = actualJson;
    let len = path.length;
    for (let i = 0; i < len - 1; i++) {
      let elem = path[i];
      if (!schema[elem]) schema[elem] = {}
      schema = schema[elem];
    }
    move(schema[path[len-1]],index,-1);
  }
  function moveDown(path, index) {
    let schema = actualJson;
    let len = path.length;
    for (let i = 0; i < len - 1; i++) {
      let elem = path[i];
      if (!schema[elem]) schema[elem] = {}
      schema = schema[elem];
    }
    move(schema[path[len-1]],index,1);
  }
  const showInputText = (key, value, parent = [], index) => {
    return (
      <div style={{ margin: "10px" }} key={key}>
        <label htmlFor={key}>{key}</label>
        {/* <input type="text" id={key} value={value} style={{ display: "block", width: "100%", padding: "5px", outline: "none" }} /> */}
        <span role="textbox" contentEditable key={key} className="textarea" suppressContentEditableWarning={true}
          onInput={(e) => {
            set([...parent, key], e.currentTarget.innerText, index);
          }}>{value}</span>
      </div>
    )
  }
  function iterateAndRemoveValuesFromObject(obj) {
    Object.entries(obj).map(entry => {
      let key = entry[0];
      let value = entry[1];
      switch (typeof (value)) {
        case 'number':
        case 'string': obj[key] = ''; break;
        case 'object': {
          if (Array.isArray(value)) {
            obj[key] = [];
          } else {
            obj[key] = iterateAndRemoveValuesFromObject(value);
          }
        }; break;
      }
    })
    return obj;
  }
  const showInputArray = (key, value, parent = [], index = null) => {
    return (
      <div style={{ margin: "10px" }} key={key}>
        <label style={{ textTransform: "capitalize", display: "block" }} htmlFor={key}>{key}</label>
        <div id={key}>
          {
            value.length > 0 ?
              value.map((item, innerindex) => {
                switch (typeof (item)) {
                  case 'string':
                    return (
                      <React.Fragment key={innerindex}>
                        <div style={{display:'inline'}}>
                          <span role="textbox" contentEditable key={innerindex} className="arrtextarea" suppressContentEditableWarning={true}
                            onInput={(e) => {
                              set([...parent, key], e.currentTarget.innerText, innerindex);
                            }}
                          >{item}</span>
                          <button style={{ padding: "5px", cursor: "pointer", margin: "5px" }} onClick={() => {
                            deleteItem([...parent, key], innerindex)
                          }}>-</button>
                        </div>
                        { innerindex !== value.length-1 ? <button style={{ padding: "5px", cursor: "pointer", margin: "5px" }} onClick={() => {
                          moveDown([...parent, key], innerindex);
                          setReRender(!reRender);
                        }}>v</button> : null }
                        { innerindex !== 0 ? <button style={{ padding: "5px", cursor: "pointer", margin: "5px" }} onClick={() => {
                          moveUp([...parent, key], innerindex);
                          setReRender(!reRender);
                        }}>^</button> : null }
                        <br></br>
                        {innerindex == value.length - 1 ? <button style={{ padding: "5px", margin: "5px", cursor: "pointer" }} onClick={() => {
                          addItem([...parent, key]);
                        }}>+</button> : null}
                      </React.Fragment>
                    )
                    break;
                  case 'object': {
                    return (
                      <div key={innerindex} style={{ border: '1px solid grey', margin: '3px', padding: '3px' }}>
                        {innerindex+1}
                        <button style={{ padding: "5px", cursor: "pointer", float: "right", margin: "5px" }} onClick={() => {
                          deleteItem([...parent, key], innerindex);
                          setReRender(!reRender);
                        }}>Delete </button>
                        { innerindex !== value.length-1 ? <button style={{ padding: "5px", cursor: "pointer", float: "right", margin: "5px" }} onClick={() => {
                          moveDown([...parent, key], innerindex);
                          setReRender(!reRender);
                        }}>Move down </button> : null }
                        { innerindex !== 0 ? <button style={{ padding: "5px", cursor: "pointer", float: "right", margin: "5px" }} onClick={() => {
                          moveUp([...parent, key], innerindex);
                          setReRender(!reRender);
                        }}>Move up </button> : null }
                        {showInputJson(null, item, [...parent, key], innerindex)}
                        {innerindex == value.length - 1 ? <button style={{ padding: "5px", cursor: "pointer" }} onClick={() => {
                          let temp = iterateAndRemoveValuesFromObject(JSON.parse(JSON.stringify(value[0])));
                          value.push(temp);
                          setReRender(!reRender);
                        }}>Add {key}</button> : null}
                      </div>
                    )
                  }
                }
              })
              :
              <React.Fragment>
                <div>
                  <span role="textbox" contentEditable className="arrtextarea" suppressContentEditableWarning={true}
                    onInput={(e) => {
                      set([...parent, key], e.currentTarget.innerText, 0);
                    }}
                  >{value[0]}</span>
                  <button style={{ padding: "5px", cursor: "pointer", margin: "5px" }} onClick={() => {
                    deleteItem([...parent, key], index)
                  }}>-</button>
                </div>
                <button style={{ padding: "5px", margin: "5px", cursor: "pointer", marginLeft: "5%" }} onClick={() => {
                  addItem([...parent, key]);
                }}>+</button>
              </React.Fragment>
          }
        </div>
      </div>
    )
  }
  //For popup of new key and value
  useEffect(() => {
    if (showNewKey === true) {
      document.getElementById('mainbody').style.opacity = '0.5';
    } else {
      document.getElementById('mainbody').style.opacity = '1';
    }
    setNewKey([]);
    setNewValue([]);
  }, [showNewKey])

  const showInputJson = (key, value, parent = [], index = null) => {
    return (
      <div style={{ margin: "10px" }} key={key}>
        <label htmlFor={key}>{key}</label>
        <input type="button" value="Add Field" style={{ marginLeft: "20px" }} onClick={() => {
          setShowNewKey(true);
          let temp = { key, parent, index };
          setTemporaryParent(temp);
        }} />
        <div id={key}></div>
        {
          Object.keys(value).map((innerkey, innerindex) => {
            switch (typeof (value[innerkey])) {
              case 'string': {
                return (
                  <div id={innerkey} key={innerindex}>{showInputText(innerkey, value[innerkey], key === null ? [...parent, index] : [...parent, key], null)}</div>
                )
              } break;
              case 'object': {
                if (Array.isArray(value[innerkey])) {
                  if (availableKeys.includes(innerkey) || !isNaN(innerkey))
                    return showInputArray(innerkey, value[innerkey], key === null ? [...parent, index] : [...parent, key], innerindex)
                }
                else {
                  if (availableKeys.includes(innerkey) || !isNaN(innerkey))
                    return showInputJson(innerkey, value[innerkey], key === null ? [...parent, index] : [...parent, key], innerindex)
                }
              }
                break;
            }
          })
        }
      </div>
    )
  }

  const showInput = (page) => {
    switch (page) {
      case 'forms': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Forms File</option>
            <option value="BW_questions">Questions</option>
            <option value="BW_scores">Scores</option>
          </select>)
      }; break;
      case 'mm-forms': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Forms File</option>
            <option value="MM_questions">Questions</option>
            <option value="MM_products">Products</option>
            <option value="MM_report">Report</option>
          </select>)
      }; break;
      case 'mm-categories': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Forms File</option>
            <option value="web_category_hair">Hair</option>
            <option value="web_category_beard">Beard</option>
            <option value="web_category_performance">Performance</option>
            <option value="web_category_skin">Skin</option>
            <option value="web_category_nutrition">Nutrition</option>
            <option value="web_category_weight">Weight</option>
            <option value="web_category_hygiene">Hygiene</option>
            <option value="web_category_sleep">Sleep</option>
          </select>)
      }; break;
      case 'mm-app-categories': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Forms File</option>
            <option value="app_category_hair">Hair</option>
            <option value="app_category_beard">Beard</option>
            <option value="app_category_performance">Performance</option>
            <option value="app_category_skin">Skin</option>
            <option value="app_category_nutrition">Nutrition</option>
            <option value="app_category_weight">Weight</option>
            <option value="app_category_hygiene">Hygiene</option>
            <option value="app_category_sleep">Sleep</option>
          </select>)
      }; break;
      case 'mm-android-jsons': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Forms File</option>
            <option value="hair">Hair</option>
            <option value="contact-us">Contact Us</option>
            <option value="faq">FAQs</option>
            <option value="home">Home</option>
            <option value="ia-data">IA Data</option>
            <option value="payment">Payment</option>
            <option value="recommended">Recommended</option>
            <option value="postorder">Post Order</option>
            <option value="products">products</option>
            <option value="shop">Shop Page</option>
            <option value="wallet">Wallet</option>
            <option value="speak-to-expert">Speak To Expert</option>
            <option value="beard">Beard</option>
            <option value="performance">Performance</option>
            <option value="skin">Skin</option>
            <option value="nutrition">Nutrition</option>
            <option value="weight">Weight</option>
            <option value="hygiene">Hygiene</option>
            <option value="sleep">Sleep</option>
            <option value="app-config">App Config</option>
          </select>)
      }; break;
      case 'mm-direct-assesment': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select DA File</option>
            <option value="app_da_questions_">Questions</option>
          </select>)
      }; break;
      case 'newhome': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select New Home</option>
            <option value="new_home">New Home</option>
            <option value="alt_new_launches">New Launches</option>
            <option value="alt_skin">New Skin</option>
            <option value="alt_hair">New Hair</option>
            <option value="alt_weight">New Weight</option>
            <option value="alt_pcos">New PCOS</option>
            <option value="alt_uti">New UTI</option>
          </select>)
      }; break;
      case 'consult': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Consult Page</option>
            <option value="nutrition">Nutrition</option>
            <option value="skin">Skin</option>
            <option value="hair">Hair</option>
          </select>)
      }; break;
      case 'faq': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Pages File</option>
            <option value="faq">FAQ</option>
          </select>)
      }; break;
      case 'home': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Pages File</option>
            <option value="home">HOME</option>
          </select>)
      }; break;
      case 'rules-json': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select Forms File</option>
            <option value="mm-pdp-rcl-rules"> MM RCL PDP</option>
            <option value="mm-pdp-rules"> MM PDP</option>
            <option value="mm-other-rules">MM OTHERS</option>
            <option value = "mm-category-pages-rules"> MM CATEGORY PAGES</option>
            <option value = "mm-community-rules"> MM COMMUNITY</option>
            <option value = "mm-consult-rules"> MM CONSULT</option>
            <option value = "mm-custom-pages-rules"> MM CUSTOM PAGES</option>
            <option value = "mm-ingredients-rules"> MM INGREDIENTS</option>
            <option value = "mm-ingredients-page-rules"> MM INGREDIENTS PAGE</option>
            <option value = "mm-landing-pages-rules"> MM LANDING PAGES</option>
            <option value = "mm-notifications-rules"> MM NOTIFICATIONS</option>
            <option value = "mm-products-category-pages-rules"> MM PRODUCTS CATEGORY PAGES</option>
            <option value = "mm-sub-category-pages-rules"> MM SUB-CATEGORY PAGES</option>
            <option value = "mm-for-you-pages-rules"> MM FOR YOU PAGES</option>
            <option value = "mm-growth-rules"> MM GROWTH</option>
            <option value = "mm-treatment-rules"> MM TREATMENT</option>
            <option value="bw-pdp-rcl-rules"> BW RCL PDP</option>
            <option value="bw-pdp-rules"> BW PDP</option>
            <option value="bw-other-rules">BW OTHERS</option>
            <option value = "bw-category-pages-rules"> BW CATEGORY PAGES</option>
            <option value = "bw-community-rules"> BW COMMUNITY</option>
            <option value = "bw-consult-rules"> BW CONSULT</option>
            <option value = "bw-custom-pages-rules"> BW CUSTOM PAGES</option>
            <option value = "bw-ingredients-rules"> BW INGREDIENTS</option>
            <option value = "bw-ingredients-page-rules"> BW INGREDIENTS PAGE</option>
            <option value = "bw-landing-pages-rules"> BW LANDING PAGES</option>
            <option value = "bw-notifications-rules"> BW NOTIFICATIONS</option>
            <option value = "bw-products-category-pages-rules"> BW PRODUCTS CATEGORY PAGES</option>
            <option value = "bw-sub-category-pages-rules"> BW SUB-CATEGORY PAGES</option>
            <option value = "bw-for-you-pages-rules"> BW FOR YOU PAGES</option>
            <option value = "bw-growth-rules"> BW GROWTH</option>
            <option value = "bw-treatment-rules"> BW TREATMENT</option>
            <option value="lj-pdp-rcl-rules"> LJ RCL PDP</option>
            <option value="lj-pdp-rules"> LJ PDP</option>
            <option value="lj-other-rules">LJ OTHERS</option>
            <option value = "lj-category-pages-rules"> LJ CATEGORY PAGES</option>
            <option value = "lj-community-rules"> LJ COMMUNITY</option>
            <option value = "lj-consult-rules"> LJ CONSULT</option>
            <option value = "lj-custom-pages-rules"> LJ CUSTOM PAGES</option>
            <option value = "lj-ingredients-rules"> LJ INGREDIENTS</option>
            <option value = "lj-ingredients-page-rules"> LJ INGREDIENTS PAGE</option>
            <option value = "lj-landing-pages-rules"> LJ LANDING PAGES</option>
            <option value = "lj-notifications-rules"> LJ NOTIFICATIONS</option>
            <option value = "lj-products-category-pages-rules"> LJ PRODUCTS CATEGORY PAGES</option>
            <option value = "lj-sub-category-pages-rules"> LJ SUB-CATEGORY PAGES</option>
            <option value = "lj-for-you-pages-rules"> LJ FOR YOU PAGES</option>
            <option value = "lj-growth-rules"> LJ GROWTH</option>
            <option value = "lj-treatment-rules"> LJ TREATMENT</option>
          </select>)
      }; break;
      case 'allproducts': {
        return (
          <select value={fileName} onChange={(e) => {
            setFileName(e.target.value);
            setJsonData("");
            setActualJson({});
            setStagingData({});
            setProductionData({});
            setShowPopup(false);
            setEnablePushToStaging(false);
            setEnablePushToProduction(false);
          }} className="fileNameInput">
            <option value="">Select File</option>
            <option value="all-products">All Products</option>
            <option value="all-products-credpay">All Products CREDPAY</option>
          </select>)
      }; break;
      default: {
        return (<input type="text" className="fileNameInput" placeholder="Enter file name" value={fileName} onChange={(e) => {
          setFileName(e.target.value);
          setJsonData("");
          setActualJson({});
          setStagingData({});
          setProductionData({});
          setShowPopup(false);
          setEnablePushToStaging(false);
          setEnablePushToProduction(false);
        }} />)
      }; break;
    }
  }

  const showProductionPopupFunction = () => {
    let productId = '';
    switch (page) {
      case 'mm-products-raw':productId = 'products/' +fileName + '.json'; break;
      case 'mm-android-products-raw':productId = 'products/android/' +fileName + '.json'; break;
      case 'lj-products-raw':productId = 'products/' +fileName + '.json'; break;
      case 'lj-pages':productId = fileName + '.json'; break;
      case 'mm-categories':productId = fileName + '.json'; break;
      case 'mm-app-categories':productId = fileName + '.json'; break;
      case 'mm-android-jsons':productId = 'android/' + fileName + '.json'; break;
      case 'products-raw':
      case 'products': productId = 'products/' + fileName + '.json'; break;
      case 'allproducts':
      case 'categories': productId = 'categories/' + fileName + '.json'; break;
      case 'regimes': productId = 'regimes/' + fileName + '.json'; break;
      case 'newhome':
      case 'pages': productId = fileName + '.json'; break;
      case 'notifications': productId = 'notifications/' + fileName + '.json'; break;
      case 'notification-bars': productId = 'notification-bars.json'; break;
      case 'search': productId = 'search.json'; break;
      case 'subscription': productId = 'subscription.json'; break;
      case 'refer-and-earn': productId = 'refer-and-earn.json'; break;
      case 'reviews-and-questions': productId = 'reviews-and-questions.json'; break;
      case 'ingredients': productId = `ingredients/${fileName}.json`; break;
      case 'pincode': productId = `pincode.json`; break;
      case 'community': productId = 'community.json'; break;
      case 'direct-assesment-flow': productId = 'direct-assesment-flow.json'; break;
      case 'custom-page': productId = fileName + '.json'; break;
      case 'direct-assesment': productId = `BW/app_da_questions_prod.json`; break;
      case 'mm-direct-assesment': productId = `app_da_questions_prod_v2.json`; break;
      case 'consult': productId = fileName + '.json'; break;
      case 'wellness-manual': productId = 'wellness-manual.json'; break;
      case 'forms': productId = 'BW/' + fileName + '_prod.json'; break;
      case 'mm-forms': productId = fileName + '_prod.json'; break;
      case 'sitemap': productId =  'sitemap.json'; break;
      case 'free-gift-mm': productId = 'mm_gift_prod.json'; break;
      case 'free-gift-bw': productId = 'bw_gift_prod.json'; break;
      case 'free-gift-lj': productId = 'lj_gift_prod.json'; break;
      case 'mm-concerns': productId =  'mm-concerns.json'; break;
      case 'mw-configurations-MM': productId = 'cod_charges_MM.json'; break;
      case 'mw-configurations-BW': productId = 'cod_charges_BW.json'; break;
      case 'mw-configurations-LJ': productId = 'cod_charges_LJ.json'; break;
      case 'mm-homepage': productId = 'web_homepage.json'; break;
      case 'mm-all-products': productId =  'web_all_products.json'; break;
      case 'mm-pdp-url-map': productId = 'mm_pdp_url_map.json'; break;
      case 'slot-capacity': productId = 'slot-capacity.json'; break;
      case 'faq': productId = fileName + '.json'; break;
      case 'rules-json': productId =fileName + '.json'; break;
    }
    Axios.post(CONFIG_CONSTANTS.dashboard_api + '/getProductData', { bucketName: productbucket, productId }, { headers: { "authorization": 'Bearer ' + localStorage.getItem('jwt') } })
      .then(result => {
        if (result.data.error) {
          setShowAlert(true);
          setAlertType("alert");
          setAlertMessage("Please enter correct File Name");
          setTimeout(() => setShowAlert(false), 4000);
        }
        else {
          setProductionData(result.data.data);
          setJSONDataForDiff(JSON.parse(jsonData));
          setTimeout(() => setShowProductionPopup(true), 1000);
        }
      })
      .catch(err => console.log(err));
  }
  const diff = () => {
    let productId = '';
    switch (page) {
      case 'mm-products-raw':productId = 'products/' + fileName + '.json'; break;
      case 'mm-android-products-raw':productId = 'products/android/' + fileName + '.json'; break;
      case 'lj-products-raw':productId = 'products/' + fileName + '.json'; break;
      case 'lj-pages':productId = fileName + '.json'; break;
      case 'mm-categories':productId =  fileName + '.json'; break;
      case 'mm-app-categories':productId =  fileName + '.json'; break;
      case 'mm-android-jsons':productId = 'android/' + fileName + '.json'; break;
      case 'products-raw':
      case 'products': productId = 'products/' + fileName + '.json'; break;
      case 'custom-page': productId = fileName + '.json'; break;
      case 'allproducts':
      case 'categories': productId = 'categories/' + fileName + '.json'; break;
      case 'regimes': productId = 'regimes/' + fileName + '.json'; break;
      case 'pages': productId = fileName + '.json'; break;
      case 'notifications': productId = 'notifications/' + fileName + '.json'; break;
      case 'sitemap': productId = 'sitemap.json'; break;
    }
    if (page === 'forms')
      productId = 'BW/' + fileName + '_staging.json';
    Axios.post(CONFIG_CONSTANTS.dashboard_api + '/getProductData', { bucketName: stagingbucket, productId }, { headers: { "authorization": 'Bearer ' + localStorage.getItem('jwt') } })
      .then(result => {
        if (result.data.error) {
          setShowAlert(true);
          setAlertType("alert");
          setAlertMessage("Please enter correct File Name");
          setTimeout(() => setShowAlert(false), 4000);
        }
        else {
          setStagingData(result.data.data);
          if (page === 'forms')
            productId = 'BW/' + fileName + '_prod.json';
          Axios.post(CONFIG_CONSTANTS.dashboard_api + '/getProductData', { bucketName: productbucket, productId }, { headers: { "authorization": 'Bearer ' + localStorage.getItem('jwt') } })
            .then(result => {
              if (result.data.error) {
                setShowAlert(true);
                setAlertType("alert");
                setAlertMessage("Please enter correct File Name");
                setTimeout(() => setShowAlert(false), 4000);
              }
              else {
                setProductionData(result.data.data);
                setTimeout(() => setShowPopup(true), 1000);
              }
            })
            .catch(err => console.log(err));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  const makeTwoDimensionaArray = (index) => {
    let temp = newKey;
    if(temp.length <= 2 && temp[index+1] === undefined)
    temp[index+1] = [[]];
    setNewKey(temp);
    temp = newKeyType;
    if(temp.length <= 2 && temp[index+1] === undefined)
    temp[index+1] = [[]];
    setNewKeyType(temp);
    temp = newValue;
    if(temp.length <= 2 && temp[index+1] === undefined)
    temp[index+1] = [[]];
    setNewValue(temp);
  }
  const createObjectFromArrays = (newKey1, newValue1, newKeyType1) => {
    if(newKeyType1[0] === 'text' || newKeyType1[0] === 'array'){
      let newObject = {};
      newObject[newKey1[0]] = newValue1[0];
      return newObject;
    }
    if(newKeyType1[0] === 'object' || newKeyType1[0] === 'arrayObject'){
      let newObject = {};
      newObject[newKey1[0]] = createObjectFromArrays(newKey1.slice(1), newValue1.slice(1), newKeyType1.slice(1));
      return newObject;
    }
    if(Array.isArray(newKeyType1[0])){
      let newObject = [];
      newKey1[0].map((item,iteratorIndex) => {
        if(newKeyType1[0][iteratorIndex] === 'object'){
          let temp = {};
          temp[newKey1[0][iteratorIndex]] = createObjectFromArrays(newKey1[1].split(" "),Array.isArray(newValue1[1]) ? [newValue1[1]] : newValue1[1].split(" "), newKeyType1[1].split(" "));
          newObject.push(temp);
        }
        else{
          newObject.push(createObjectFromArrays(newKey1[0][iteratorIndex].split(" "),Array.isArray(newValue1[0][iteratorIndex]) ? [newValue1[0][iteratorIndex]] : newValue1[0][iteratorIndex].split(" "), newKeyType1[0][iteratorIndex].split(" ")));
        }
      })
      return newObject;
    }
  }
  const AddNewKey = (props) => {
    let index = props['index'];
    let index1 = props['index1'];
    let margin = props['margin']+'px';
    const [arrIndex, setArrIndex] = useState([0]);
    const [reRender, setReRender] = useState(false);
    return (
      <>
      <input type="text" placeholder="Key" value={index1 !== undefined ? !!newKey[index+1][index1] ? newKey[index+1][index1] : "" : !!newKey[index] ? newKey[index] : ""} onChange={(e) => {
        let temp = newKey;
        index1 !== undefined ? temp[index+1][index1] = e.target.value : temp[index] = e.target.value;
        setNewKey(temp);
        setReRender(!reRender);
      }} style={{marginLeft: margin}}/>
      <select defaultValue="disabled" onChange={(e)=>{
        let temp = newKeyType;
        index1 !== undefined ? temp[index+1][index1] = e.target.value : temp[index] = e.target.value;
        setNewKeyType(temp);
        if(e.target.value === 'array'){
          let temp1 = newValue;
          index1 !== undefined ? temp1[index+1][index1] = [] : temp1[index]=[];
          setNewValue(temp1);
        }
        setReRender(!reRender);
      }} style={{marginLeft: margin}}>
        <option disabled value="disabled"> -- select an option -- </option>
        <option value="text">Text</option>
        <option value="array">List (Text)</option>
        {index1 !== undefined ? null : <option value="arrayObject">List(Objects) </option> }
        <option value="object">Object</option>
      </select>
      {newKeyType[index] === 'text' || index1 !== undefined && newKeyType[index+1][index1] === 'text' ? <textarea value={index1 !== undefined ? newValue[index+1][index1] : newValue[index]} placeholder="Value" onChange={(e) => {
        let temp = newValue;
        index1 !== undefined ? temp[index+1][index1] = e.target.value : temp[index] = e.target.value;
        setNewValue(temp);
        setReRender(!reRender);
      }} style={{marginLeft: margin}}/> : null}
        {newKeyType[index] === 'array' || index1 !== undefined && newKeyType[index+1][index1] === 'array' ?
          <>
            {arrIndex.map((item, tempindex) => {
              return (
                <React.Fragment key={tempindex}>
                  <textarea value={index1 !== undefined ? newValue[index+1][index1][tempindex] : newValue[index][tempindex]} placeholder="Value" onChange={(e) => {
                    let temp = newValue;
                    index1 !== undefined ? temp[index+1][index1][tempindex] = e.target.value : temp[index][tempindex] = e.target.value;
                    setNewValue(temp);
                    setReRender(!reRender);
                  }} style={{ marginLeft: margin }} />
                </React.Fragment>
              )
            })}

            <button style={{ padding: "5px", margin: "5px", cursor: "pointer", marginLeft: "5%" }} onClick={() => {
              let temp = arrIndex;
              temp.push(0);
              setArrIndex(temp);
              setReRender(!reRender);
            }}>+</button><br></br>
          </>
          :
          null}
        {newKeyType[index] === 'arrayObject' && index1 === undefined || index1 !== undefined && newKeyType[index][index1] === 'arrayObject'?
        <>
          {makeTwoDimensionaArray(index)}
          {arrIndex.map((item, index1) => {
              return (
                <AddNewKey index={index} margin={props['margin']+10} index1={index1} key={index1}/>
              )
            })}
            <button style={{ padding: "5px", margin: "5px", cursor: "pointer", marginLeft: "5%" }} onClick={() => {
              let temp = arrIndex;
              temp.push(0);
              setArrIndex(temp);
              setReRender(!reRender);
            }}>+</button><br></br>
        </>
        :
        null}
      { newKeyType[index] === 'object' ? <AddNewKey index={index+1} margin={props['margin']+10}/> : null}
      { index1 !== undefined && newKeyType[index+1][index1] === 'object' ? <AddNewKey index={index+2} margin={props['margin']+10} /> : null}
      </>
    )
  }
  return (
    <div className="App">
        {showNewKey ?
        <div className="newKeyPopup">
          <div className="newKeyContent">
            <h2>Add New</h2>
            <AddNewKey index={0} margin={0}/>
            <input className="primary" type="button" value="SAVE" onClick={() => {
              let obj = createObjectFromArrays(newKey,newValue, newKeyType);
              obj = Array.isArray(newKey) ? obj[newKey[0]] : obj[newKey];
              if(temporaryParent['isRoot']){
                Array.isArray(newKey) ? actualJson[newKey[0]] = obj : actualJson[newKey] = obj;
              } else{
                let schema = actualJson;
                let len = temporaryParent.parent.length;
                for (let i = 0; i < len - 1; i++) {
                  let elem = temporaryParent.parent[i];
                  if (!schema[elem]) schema[elem] = {}
                  schema = schema[elem];
                }
                let tempNewKey = Array.isArray(newKey) ? newKey[0] : newKey;
                len > 0 ?
                !!temporaryParent.key ? schema[temporaryParent.parent[len - 1]][temporaryParent.key][tempNewKey] = obj : schema[temporaryParent.parent[len-1]][temporaryParent.index][tempNewKey] = obj:
                schema[temporaryParent.key][tempNewKey] = obj;
              }
              setShowNewKey(false);
              setTemporaryParent({});
              setNewKeyType([]);
              setNewKey([]);
              setNewValue([]);
            }} />
            <input className="primary" style={{ backgroundColor: "red" }} type="button" value="CANCEL" onClick={() => {
              setShowNewKey(false);
              setTemporaryParent({});
              setNewKeyType([]);
              setNewKey([]);
              setNewValue([]);
            }} />
          </div>
        </div>
        : null}
      <div style={{ width: "80%", margin: "0 auto" }} id="mainbody">
        <h2 style={{ margin: "10px" }}>{page.toUpperCase()}</h2>
        {showInput(page)}
        <input className="primary" type="button" value="Get Staging Data" onClick={() => getData(false)} />
        <input className="primary" type="button" value="Get Production Data" onClick={() => getData(true)} />
        <input className="primary" type="button" value="Logout" onClick={() => { localStorage.clear(); history.push('/') }} style={{ float: "right" }} />
        <input className="primary" type="button" value="Clear" onClick={() => clear()} style={{ float: "right" }} />
        <input className="primary" type="button" value="Stag/Prod Diff" onClick={() => diff()} style={{ float: "right" }} />
        {/* <Editor value={jsonData}></Editor> */}
        <div style={{ margin: "5px" }}>
          <a
            href="https://docs.google.com/document/d/1xCrFAPkVd7Nr1NEBfQeIWWX2TsN5yAUlpeHR2Tp3sWk/edit#heading=h.r877nf5u98sg"
            target="_blank"
          >
            JSON Reference Sheet
          </a>
        </div>
        {page === 'products' || page === 'categories' ||  page === 'home' ?
          <div>
            {Object.keys(actualJson).length !== 0?
              <input type="button" value="Add Field (Root)" style={{ marginLeft: "20px" }} onClick={() => {
                setShowNewKey(true);
                setTemporaryParent({isRoot: true});
              }} />
            : null}
            {
              Array.isArray(actualJson) ?
                actualJson.map((item, index) => {
                  return Object.keys(item).map((key, innerindex) => {
                    if (availableKeys.includes(key) || !isNaN(key)) {
                      switch (typeof (item[key])) {
                        case 'string': return showInputText(key, item[key], [index]); break;
                        case 'object':
                          if (Array.isArray(item[key])) {
                            return showInputArray(key, item[key], [index])
                          }
                          else {
                            return showInputJson(key, item[key], [index])
                          }
                          break;
                      }
                    }
                  })
                })
                :
                Object.keys(actualJson).map((key, index) => {
                  if (availableKeys.includes(key) || !isNaN(key)) {
                    switch (typeof (actualJson[key])) {
                      case 'string': return showInputText(key, actualJson[key]); break;
                      case 'object':
                        if (Array.isArray(actualJson[key])) {
                          return showInputArray(key, actualJson[key])
                        }
                        else {
                          return showInputJson(key, actualJson[key])
                        }
                        break;
                    }
                  }
                })
            }
          </div>
          :
          <div>
            <textarea value={jsonData} onBlur={() => validateJson()} style={{ minHeight: "65vh", width: "100%", outline: "none", resize: "none" }}
              onChange={(e) => { setJsonData(e.target.value); setEnablePushToStaging(false); setEnablePushToProduction(false); }} spellCheck="false"
            />
          </div>
        }
        <div style={{ width: "50%", display: "inline-block" }}>
          <input
            className="primary"
            type="button"
            value="Validate JSON"
            onClick={() => validateJson(true)}
          />
          {showAlert ? <Alert></Alert> : null}
        </div>
        <div
          style={{ width: "50%", display: "inline-block", textAlign: "right" }}
        >
          <input
            className="primary"
            type="button"
            value="Push to Staging"
            disabled={!enablePushToStaging}
            onClick={() => pushToS3()}
          />
          <input
            className="primary"
            type="button"
            value="Push to Production"
            disabled={!enablePushToProduction}
            onClick={() => showProductionPopupFunction()}
          />
        </div>
      </div>

      { result.length > 0 ? <div style={{ width: "100%", display: "block", margin: "0 auto", textAlign: "left", marginBottom: "50px" }}>
        <div style={{ color: "red", fontSize: "20px", textAlign: "center" }}>Unable to validate json</div>
        <br />
        <div >Some image urls were found without the correct transformation "/f_auto,w_800,c_limit/"
        <div >Please format the following keys(If all keys are not present, please contact Admin) image urls with this transformation:</div>
          <div >Example : "https://res.cloudinary.com/mosaic-wellness/image/upload/f_auto,w_800,c_limit/staging/products/acne-control-wash/tesimonials/Before.jpg"</div>
          <br /><br />
        </div>
        {result.map((item) => (
          <div >
            <div>{Object.keys(item)} {" : "} {item[Object.keys(item)]}</div>
            <br />
          </div>
        ))}
      </div> : ""}
      {/* Spinner */}
      { spinner ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : null}
      {/* Show Diff */}
      {/* { showPopup ? <Popup oldCode={stagingData} newCode={productionData}></Popup> : null} */}
      <Modal show={showPopup} onHide={()=>setShowPopup(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Staging -- Production</Modal.Title>
        </Modal.Header>
        <Modal.Body><Popup oldCode={stagingData} newCode={productionData} leftTitle="Staging" rightTitle="Production"></Popup></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowPopup(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

        {/* Popup for push to production confirmation  */}
        <Modal show={showProductionPopup} onHide={()=>setShowProductionPopup(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Production -- Current</Modal.Title>
        </Modal.Header>
        <Modal.Body><Popup oldCode={productionData} newCode={page === 'products' || page === 'categories' || page === 'home' ? actualJson : jsonDataForDiff } leftTitle="This is on Production" rightTitle="You are going to push this to Production"></Popup></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowProductionPopup(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{pushToS3(true);setShowProductionPopup(false)}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default Products;
