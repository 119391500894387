import React, { useState } from "react";
import DataTable from "../common/Tables/DataTable";
import config from "../../constants/apiList";
import moment from "moment";

const dateToday = moment().format("YYYY-MM-DD");

export default function ProductOrders() {
  const [dates, setDates] = useState({
    start: dateToday,
    end: dateToday
  });
  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };
  const reqBody = {
    type: "orders",
    startDate: moment(dates.start).unix(),
    endDate: moment(dates.end).unix() + 24 * 60 * 60
  };
  return (
    <div className="admin-page-container">
      <div className="dategroup">
        <form className="form">
          <input
            type="date"
            className="input"
            value={dates.start}
            onChange={e => handleChange(e, "start")}
          />
          <input
            type="date"
            className="input"
            value={dates.end}
            onChange={e => handleChange(e, "end")}
          />
        </form>
      </div>
      <DataTable
        title="Product Orders"
        cols={orderCols}
        url={config.lj_product_order}
        reqBody={reqBody}
        fromS3={true}
      />
    </div>
  );
}

const orderCols = [
  "order_id",
  "created_at",
  "customerEmail",
  "customerName",
  "customerPhoneNumber",
  "order_status",
  "order_state",
  "coupon_code",
  "rewards.spend_points",
  "rewards.regime_reward",
  "discount",
  "order_total",
  "customer",
  "city",
  "order_items",
  "order_comments",
  "payment_reference_id",
  "transaction_details.txn_id",
  "transaction_details.parent_txn_id",
  "payment_method"
].map(col => {
  return {
    title: col,
    field: col
  };
});
