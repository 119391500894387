import React, { useState } from "react";
import { Link } from "react-router-dom";
import CopyProductPopup from "./CopyProductPopup";
function ProductDashboard() {
   const [displayPopup,setDisplayPopup] = useState(false);
   const [isCopyCustomProduct, setCopyCustomProduct] = useState(false);
   const [copyBrand, setcopyBrand] = useState("bw");
   var setPopup=()=>{
     if(displayPopup){
      setDisplayPopup(false);
     }else{
     setDisplayPopup(true);
   }
  }
  return (
    <div>
      { displayPopup && <CopyProductPopup isCopyCustomProduct={isCopyCustomProduct} setPopup={setPopup} copyBrand={copyBrand}/> }
      <div>
        {/* <Link to="/product/products">
          <button className="primary">PRODUCTS</button>
        </Link> */}
        {/* Disabled By Akshay */}
        {/* <Link to="/product/categories">
          <button className="primary">CATEGORIES</button>
        </Link>
        <Link to="/product/regimes">
          <button className="primary">REGIMES</button>
        </Link>
        <Link to='/product/allproducts'>
            <button className="primary">ALL PRODUCTS</button>
        </Link>
        <Link to="/product/forms">
          <button className="primary">FORMS</button>
        </Link>
        <Link to="/product/mm-forms">
          <button className="primary">FORMS-MM</button>
        </Link>
        <Link to="/product/bwforms">
          <button className="primary">EXPORT FORMS</button>
        </Link>
        <Link to="/product/orders">
          <button className="primary">BW EXPORT ORDERS</button>
        </Link>
        <Link to="/product/mm-orders">
          <button className="primary">MM EXPORT ORDERS</button>
        </Link>
        <Link to="/product/lj-orders">
          <button className="primary">LJ EXPORT ORDERS</button>
        </Link>
        <Link to="/product/subscription-orders">
          <button className="primary">EXPORT SUBSCRIPTION ORDERS</button>
        </Link>
        <Link to="/product/subscriptions">
          <button className="primary">MANAGE SUBSCRIPTIONS</button>
        </Link>
        <Link to="/product/free-gifts">
          <button className="primary">FREE GIFTS</button>
        </Link>
        <Link to ='/product/faq'><button className="primary">FAQ</button></Link>
        <Link to ='/product/home'><button className="primary">HOME</button></Link>
        <Link to ='/product/newhome'><button className="primary">NEW HOME</button></Link>
        <Link to="/product/pages">
          <button className="primary">PAGES</button>
        </Link>
        <Link to="/product/notifications">
          <button className="primary">NOTIFICATIONS</button>
        </Link>
        <Link to ="/product/products-raw">
          <button className="primary">PRODUCTS RAW</button>
        </Link> */}
        {/* Disabled By Akshay */}

        
          {/* <button className="primary" onClick = {() => {
          setPopup();
          setCopyCustomProduct(false);
        }}>COPY PRODUCT</button>

          <button className="primary" onClick = {() => {
          setPopup();
          setCopyCustomProduct(false);
          setcopyBrand("lj")
        }}>LJ COPY PRODUCT</button>
          <button className="primary" onClick = {() => {
          setPopup();
          setCopyCustomProduct(false);
          setcopyBrand("mm")
        }}>MM COPY PRODUCT</button> */}

        {/* Disabled By Akshay */}

        {/* <Link to ="/product/notification-bars">
          <button className="primary">NOTIFICATION BARS</button>
        </Link>
        <Link to ="/product/search">
          <button className="primary">SEARCH</button>
        </Link>
        <Link to ="/product/subscription">
          <button className="primary">ALL SUBSCRIPTIONS PAGE</button>
        </Link>
        <Link to ="/product/refer-and-earn">
          <button className="primary">REFER AND EARN</button>
        </Link>
        <Link to ="/product/reviews-and-questions">
          <button className="primary">REVIEWS AND QUESTIONS</button>
        </Link>
        <Link to ="/product/ingredients">
          <button className="primary">INGREDIENT PAGES</button>
        </Link>
        <Link to ="/product/pincode">
          <button className="primary">PINCODE ESTIMATE</button>
        </Link>
        <Link to = "/product/mm-products-raw">
          <button className="primary">MM PRODUCTS RAW</button>
         </Link>
        <Link to = "/product/mm-android-products-raw">
          <button className="primary">MM ANDROID PRODUCTS RAW</button>
         </Link>
        <Link to = "/product/lj-products-raw">
          <button className="primary">LJ PRODUCTS RAW</button>
         </Link>
        <Link to = "/product/lj-pages">
          <button className="primary">LJ PAGES</button>
         </Link>
        <Link to = "/product/mm-categories">
          <button className="primary">MM WEB CATEGORIES</button>
         </Link> 
        <Link to = "/product/mm-app-categories">
          <button className="primary">MM APP CATEGORIES</button>
         </Link> 
        <Link to = "/product/mm-android-jsons">
          <button className="primary">MM ANDROID PAGES</button>
         </Link> 
        <Link to ="/product/community">
          <button className="primary">COMMUNITY PAGE</button>
        </Link>
        <Link to ="/product/custom-page">
          <button className="primary">CUSTOM PAGE</button>
        </Link>
        <button className="primary" onClick = {() => {
          setPopup();
          setCopyCustomProduct(true);
        }}>COPY CUSTOM PAGE</button>
        <Link to ="/product/direct-assesment">
          <button className="primary">DIRECT ASSESMENT</button>
        </Link>
        <Link to ="/product/mm-direct-assesment">
          <button className="primary">DIRECT ASSESMENT - MM</button>
        </Link>
        <Link to ="/product/direct-assesment-flow">
          <button className="primary">DIRECT ASSESMENT FLOW</button>
        </Link>
        <Link to ="/product/consult">
          <button className="primary">CONSULT PAGE</button>
        </Link>
        <Link to ="/product/wellness-manual">
          <button className="primary">WELLNESS MANUAL</button>
        </Link>
        <Link to ="/product/sitemap">
          <button className="primary">SITEMAP</button>
        </Link>
        <Link to ="/product/free-gift-mm">
          <button className="primary">MM FREE GIFT</button>
        </Link>
        <Link to ="/product/free-gift-bw">
          <button className="primary">BW FREE GIFT</button>
        </Link>
        <Link to ="/product/free-gift-lj">
          <button className="primary">LJ FREE GIFT</button>
        </Link>
        <Link to ="/product/mm-concerns">
          <button className="primary">MM CONCERNS</button>
        </Link>
        <Link to ="/product/mw-configurations-MM">
          <button className="primary">MW CONFIGURATIONS MM</button>
        </Link>
        <Link to ="/product/mw-configurations-BW">
          <button className="primary">MW CONFIGURATIONS BW</button>
        </Link>
        <Link to ="/product/mw-configurations-LJ">
          <button className="primary">MW CONFIGURATIONS LJ</button>
        </Link>
        <Link to ="/product/mm-homepage">
          <button className="primary">MM WEB HOMEPAGE</button>
        </Link>
        <Link to ="/product/mm-all-products">
          <button className="primary">MM WEB ALL PRODUCTS</button>
        </Link>
        <Link to ="/product/mm-pdp-url-map">
          <button className="primary">MM PDP URL MAP</button>
        </Link>
        <Link to ="/product/slot-capacity">
          <button className="primary">SLOT CAPACITY</button>
        </Link> */}
        {/* Disabled By Akshay */}
        <Link to="/product/forms">
          <button className="primary">FORMS</button>
        </Link>
        <Link to="/product/mm-forms">
          <button className="primary">FORMS-MM</button>
        </Link>
        <Link to="/product/bwforms">
          <button className="primary">EXPORT FORMS</button>
        </Link>
        <Link to="/product/orders">
          <button className="primary">BW EXPORT ORDERS</button>
        </Link>
        <Link to="/product/mm-orders">
          <button className="primary">MM EXPORT ORDERS</button>
        </Link>
        <Link to="/product/lj-orders">
          <button className="primary">LJ EXPORT ORDERS</button>
        </Link>
        <Link to ="/product/free-gift-mm">
          <button className="primary">MM FREE GIFT</button>
        </Link>
        <Link to ="/product/free-gift-bw">
          <button className="primary">BW FREE GIFT</button>
        </Link>
        <Link to ="/product/free-gift-lj">
          <button className="primary">LJ FREE GIFT</button>
        </Link>

        <Link to ="/product/rules-json">
          <button className="primary">RULES JSON</button>
        </Link>  
      </div>
    </div>
  );
}
export default ProductDashboard;
