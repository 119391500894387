
import React,{useState} from "react";
import { Button ,Modal,Toast} from 'react-bootstrap';
import Axios from 'axios';
import CONFIG_CONSTANTS from "../../constants/apiList";

function CopyProductPopup(props){
    const [oldProduct, setOldProduct] = useState("");
    const [newProduct, setNewProduct] = useState("");
    const [displayToast,setDisplayToast] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const {isCopyCustomProduct, copyBrand} = props;
    const url = CONFIG_CONSTANTS.dashboard_api;
    
    const handleDuplicate = () => {
        console.log('oldProduct',oldProduct,'newProduct',newProduct)
        
         Axios.post(url + (isCopyCustomProduct ? "/addDuplicateCustomPageProduct" : "/addDuplicateProduct"), 
         { productId: oldProduct+'.json',
         newProductId:newProduct+'.json',
         copyBrand: copyBrand
        },
         { headers: { "authorization": 'Bearer ' + localStorage.getItem('jwt') }})
         .then((result)=>{  
             console.log(result.data.message);
             if(result.status!=200){
                setDisplayToast(true); 
                setErrorMessage(result.data.message)
             }else{
                props.setPopup();
             }
         },(err)=>{   console.log('error in copying Product')})
        
    }

    return ( 
        <>
          <Modal show={true} onHide={props.setPopup}>
            <Modal.Header closeButton>
              <Modal.Title>Copy Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>This will create a copy of the given Product from Staging.</Modal.Body>
            <Modal.Footer>
                <input placeholder="Old Product" value={oldProduct} onChange={(e) => setOldProduct(e.target.value)}></input>
                <input placeholder="New Product" value={newProduct} onChange={(e) => setNewProduct(e.target.value)}></input>
             
              <Button variant="primary" onClick={handleDuplicate}>
                Duplicate
              </Button>
              <Toast onClose={() => setDisplayToast(false)} show={displayToast} delay={3000} autohide>
         
                <Toast.Body style={{color:'red'}}>{errorMessage}</Toast.Body>
        </Toast>
            </Modal.Footer>
          </Modal>
        </>);
}

export default CopyProductPopup;